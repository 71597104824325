import React from 'react';

export default function Main() {
    return (
        <main>
            <section class="py-5 text-center container">
                <div class="row py-lg-5">
                    <div class="col-lg-6 col-md-8 mx-auto">
                        <h1 class="fw-light">AI Assist Learning</h1>
                        <p class="lead text-body-secondary">Artificial Intelligence (AI) is revolutionizing the educational landscape, offering personalized learning experiences and supporting both students and educators in various capacities.
                        </p>
                        <p>
                            <a href="#" class="btn btn-primary my-2">AI Assist ELA</a>
                            <a href="#" class="btn btn-secondary my-2">AI Assist Math</a>
                            <a href="#" class="btn btn-secondary my-2">AI Assist CP</a>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    );
}