import React from 'react';

export default function Footer() {
    const nav_items = ['Home', 'ELA', 'Math', 'CP', 'About'];
    return (
        <footer class="py-3 my-4">
            <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                {
                    nav_items.map((item, i) => (
                        <li key={i} class="nav-item"><a href={item.toLowerCase()} class="nav-link px-2 text-body-secondary">{item}</a></li>
                    ))
                }
            </ul>
            <p class="text-center text-body-secondary">&copy; 2024 Eachcan Learning LLC</p>
        </footer>
    );
}