import React from 'react';

export default function Header(props) {
    const { name } = props;
    const nav_items = ['Home', 'ELA', 'Math', 'CP', 'About'];
    return (

        <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
            <a href="/"
                class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <span class="fs-4">[EC^2]</span>
            </a>
            <ul class="nav nav-pills">
                {nav_items.map((item, i) =>
                    item === name ?
                        <li key={i} class="nav-item"><a href={item.toLowerCase()} class="nav-link active" aria-current="page">{item}</a></li> :
                        <li key={i} class="nav-item"><a href={item.toLowerCase()} class="nav-link">{item}</a></li>
                )}
            </ul>
        </header>
    );
}