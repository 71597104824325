import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Ela from '../ELA/ELA';
import Math from '../Math/Math';
import Cp from '../CP/CP';
import About from '../About/About';

function App() {
  return (
    <div class="container">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/ela" element={<Ela />} />
          <Route path="/math" element={<Math />} />
          <Route path="/cp" element={<Cp />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;