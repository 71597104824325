import React from 'react';
import useFetch from "../../hooks/useFetch";
export default function DailyExercise() {
    const { loading, data: elaData, error } = useFetch(
        `${process.env.REACT_APP_ELA_DATA_FILE}`
    );

    const date = new Date();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);

    if (loading) return <h1>loading...</h1>;
    if (error)
        return <pre>{JSON.stringify(error, null, 2)}</pre>;
    return (
        <main>
            <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                <div class="list-group list-group-checkable d-grid gap-2 border-0">
                    {elaData.daily_exercise_data.map((item, i) => {
                        const inputId = "listGroupCheckableRadios" + (i + 1);
                        return <>
                            {item.date === formattedDate ?
                            <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
                                id={inputId} value="" checked/>
                            :
                            <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
                                id={inputId} value="" />
                            }
                            <label class="list-group-item rounded-3 py-3" for={inputId}>
                                {item.date}
                                {
                                    item.exercises.map((ex, j) => {
                                        return <span class="d-block small opacity-50"><a class="text-white" href={ex.url}>{ex.name}</a></span>
                                    })
                                }
                            </label>
                        </>
                    }
                    )}
                </div>
            </div>
        </main>
    );
}