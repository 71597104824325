import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import './ela.css';
import DailyExercise from './DailyExercise4';


export default function Ela() {
    return (
        <>
            <Header name="ELA" />
            <DailyExercise />
            <Footer />
        </>
    );
}