import React from 'react';
import Header from '../common/Header';
import Main from '../common/Main';
import Footer from '../common/Footer';

export default function Cp() {
    return (
        <>
            <Header name="CP" />
            <Main />
            <Footer />
        </>
    );
}